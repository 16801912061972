import { LOCALES } from './locales'

export const messages = {
    [LOCALES.ENGLISH]: {
        username: 'Username',
        password:
            'Password',
        terminal_operation:
            'For terminal operation and cooperation:',
        tel: 'Tel:',
        // меню
        login: 'Log in',
        working_mode: 'Working mode:',
        working_mode_text: 'Daily and around the clock',
        container_terminal: 'Container Yard',
        esino: 'ESINO',
        exit: 'Logout',
        reload: 'Reload',
        reload_store: 'Reload and store',
        terminal_esino: 'Esino Container Yard',
        terminal_ccd: 'CCD East Container Yard',
        containers: 'Conteiners',
        orders: 'Applications',
        movement: 'Movement',
        export_application: 'Export Application',
        reload_application: 'Reload Application',
        export_application2: 'Numbered issue',
        import_application: 'Import Application',
        no_orders: 'No applications',
        date_from: 'Date from',
        date_to:  "Date to",
        container: "Container",
        type: "Type",
        total: "Total",
        on_car: "On car",
        ready: "Ready",
        release: "Release",
        applicant: "Applicant",
        status: "Status",
        new: 'New',
        agreed: 'Agreed',
        in_work: 'In work',
        closed: 'Closed',
        denied:'Denied',
        import:'Import',
        export:'Export',
        order_title_in: 'Application for the import of a container to the terminal according to the following details',
        order_title_out: 'Application for the export of a container from the terminal using the following details',
        order_title_reload: 'Заявка на перегруз контейнера по следующим реквизитам',
        order_title_reload_store: 'Заявка на перегруз и хранения контейнера на терминале по следующим реквизитам',
        issue_terminal:'Issue from terminal',
        accept_terminal: 'Accept at terminal',
        additional_information:'Additional information',
        additional_email: 'Additional e-mail for notification',
        containers_on_request:'Containers on request',
        size: 'Size',
        tonnage: 'Tonnage',
        quantity: 'Qty.',
        number_container: '# Container',
        car_on_request:'Cars on request',
        number: 'Number',
        driver: 'Driver',
        phone: 'Phone',
        add_container: 'Add container',
        add_auto: 'Add Auto',
        create: 'Create',
        save: 'Save',
        cancel:'Cancel',
        cancel_order:'Order cancel',
        back: 'Back',
        send: 'Send',
        canceled: 'Сanceled',
        no_containers: 'No containers at the terminal',
        state: 'State',
        content: 'Content',
        contf: 'Content(empty/full)',
        cont: 'Content',
        containers_title: 'Containers at the terminal',
        days: 'Storage',
        movings_title: 'Container movement',
        no_movings: 'No movement',
        unblocked: 'Unblocked',
        blocked: 'Blocked',
        overdue: 'Overdue',
        action: 'Action',
        documents:'Documents',
        date: 'Date',
        stock: 'Stock',
        car: 'Car',
        order: 'Order',
        comment: 'Comment',
        car_number: 'Car number',
        prolong: 'Prolong',
        m_in: 'In',
        m_out: 'Out',
        storage: 'Storage',
        photo: 'Photo',
        carrier: 'Carrier(unknown driver)',
        add: 'Add',
        incorrect_number: "Incorrect container numbers",
        list_containers: 'Add container list',
        list_containers_message: 'Enter container numbers as a list. Container numbers must be separated by spaces or commas or written on a new line.',
        bills_title: 'Bills',
        no_bills: 'No Bills',
        bill_number: 'Bill number',
        bill_amount: 'Bill amount',
        bill_payment_status: "Payment status",
        bill_download: "Download",
        bill_registry: "Registry",
        bill: "Bill",
        bills: "Bills",
        none: "not paid",
        partial: "paid in part",
        full: "paid",
        download: "Download",
        empty: "Empty",
        fullc: "Loaded",
        fullContainer: 'Loaded Container',
        intact: "Intact",
        damaged: "Damaged",
        price_repair: "Repair cost",
        car_delivery: "Car for delivery",
        car_delivery2: "Car for delivery",
        terminalpay:'Terminal payment required',
        delivery_address: "Address",
        agreed_price_per_car: "Agreed price per car",
        per_car: " per car",
        close: "Acceptance of containers requires prior appointment",
        attention: "Attention!",
        registration: "Click the button below.",
        enraice: "Login to account",
        containers_count: "Total",
        yes: "Yes",
        no: "No",
        repair_estimate:"Request cost of repairing container",
        repair_estimate2:"from terminal?",
        check_release:'Check release and book place',
        check_release2:'Check',
        check_release3:'Availability of a release or application by container number',
        created: "Created",
        createdBy: "Created by",
        repairs: "Repairs",
        repairs_title: "Repair requests",
        repairs_not: "No requests for repairs",
        services: 'Services',
        services_title: "Services requests",
        services_not: "No requests for services",
        estimate_date: "Estimate date",
        pc: "PC",
        crane_operations: "Crane Operations",
        agree_estimate: "Accept proposed container",
        agree_estimate2: "repair cost?",
        accept: "Accept",
        refuse: "Refuse",
        accept_cancel: "Accept/Cancel",
        ready_repair: "Repairs carried in",
        waiting_import:"Waiting to import",
        waiting_export:"Waiting to export",
        extend_application: "Extend application",
        till: "till",
        extend: "Extend",
        _no: "#",
        full_container: ' - (loaded)',
        refurbished: "Refurbished",
        refused_to_refurbish: "Refused to refurbish",
        refuse_to_refurbish: "Refuse to refurbish",
        incorrect_password: "Incorrect password",
        user_blocked: "This user blocked",
        user_not_found: "User not found",
        photo_containers: "Photo containers",
        reject_container_repair: "Reject container repair ",
        waiting: "Waiting",
        generate_report: 'Generate report',
        range: 'Date period',
        all: 'All',
        show: 'Show',
        photo_containers_title: "View photos of containers by number",
        application_insulation: "Application for insulation",
        insulation: "Insulation",
        one_layer: "1 layer (5 cm)",
        two_layer: "2 layers (10 cm)",
        three_layer: "3 layers (15 cm)",
        roof: "Roof",
        hp_year1: "Dear terminal customers!",
        hp_year2: "We inform you that from 19:00 on 31-12-2024 to 10:00 on 01-01-2025, the ESINO terminal will not work.",
        hp_year3: "Please take this into account when planning your work.",
        plas: "Polyethylene",
        number_out: "Numbered issue",
        number_out_text: "Is numbered issuance expected for the containers listed below?",
        photo_report: "Photo report",
        photo_report_text: "Photography from 4 sides, cost according to the tariff",
        list_container_type:"Container type",
        list_containet_type_text:"If the request contains a container of the same type, you can indicate in this field",

    },
    [LOCALES.RUSSIAN]: {
        username: 'Имя пользователя',
        password:
            'Пароль',
        terminal_operation:
            'По вопросам работы терминала и сотрудничества:',
        tel: 'Тел.:',
        // меню
        login: 'Войти',
        hp_year1: "Уважаемые клиенты терминала!",
        hp_year2: "Сообщаем, что с 19:00 31.12.2024 по 10:00 01.01.2025 терминал Есино работать не будет.",
        hp_year3: "Просим учитывать это при планировании вашей работы.",
        working_mode: 'Режим работы:',
        working_mode_text: 'Ежедневно и круглосуточно',
        container_terminal: 'Контейнерный терминал',
        esino: 'ЕСИНО',
        exit: 'Выход',
        terminal_esino: 'Терминал Есино',
        terminal_ccd: 'Терминал ЦКАД Восток',
        containers: 'Контейнеры',
        orders: 'Заявки',
        movement: 'Движение',
        export_application: 'Заявка на вывоз',
        reload_application: 'Заявка на перегрузку',
        export_application2: 'Номерная выдача',
        import_application: 'Заявка на завоз',
        no_orders: 'Нет заявок',
        date_from: 'Дата с',
        date_to:  "Дата до",
        container: "Контейнер",
        type: "Тип",
        total: "Всего",
        on_car: "На а/м",
        ready: "Выполнено",
        release: "Релиз",
        applicant: "Заявитель",
        status: "Статус",
        content: "Содержимое",
        refurbished: "Отремонтирован",
        refused_to_refurbish: "Отказались от ремонта",
        new: 'Новая',
        agreed: 'Согласовано',
        in_work: 'Исполняется',
        closed: 'Закрыта',
        denied:'Отказано',
        import:'Завоз',
        export:'Вывоз',
        reload: 'Перегруз',
        reload_store: 'Перегруз и хранение',
        order_title_in: 'Заявка на ввоз контейнера на терминал по следующим реквизитам',
        order_title_reload: 'Заявка на перегруз контейнера по следующим реквизитам',
        order_title_reload_store: 'Заявка на перегруз и хранения контейнера на терминале по следующим реквизитам',

        order_title_out: 'Заявка на вывоз контейнера с терминала по следующим реквизитам',
        issue_terminal:'Выдать с терминала',
        accept_terminal: 'Размещение',
        additional_information:'Дополнительная информация',
        additional_email: 'Дополнительный e-mail для оповещения',
        containers_on_request:'Контейнера по заявке',
        size: 'Размер',
        tonnage: 'Тоннаж',
        quantity: 'Кол-во',
        number_container: '№ контейнера',
        car_on_request:'Авто по заявке',
        number: 'Номер',
        driver: 'Водитель',
        phone: 'Телефон',
        add_container: 'Добавить контейнер',
        add_auto: 'Добавить авто',
        create: 'Создать',
        created: "Cоздана",
        save: 'Сохранить',
        cancel:'Отменить',
        cancel_order:'Отменить заявку',
        back: 'Назад',
        send: 'Отправить',
        canceled: 'Отменена',
        no_containers: 'Нет контейнеров на терминале',
        state: 'Состояние',
        cont: 'Содержимое',
        contf: 'Содержимое(порожний/груженый)',
        containers_title: 'Контейнеры на терминале',
        days: 'Хра-нение',
        movings_title: 'Движение контейнеров',
        no_movings: 'Нет движений',
        unblocked: 'Разблокировать',
        blocked: 'Заблокировать',
        overdue: 'Просрочена',
        action: 'Операция',
        documents:'Документы',
        date: 'Дата',
        stock: 'Сток',
        car: 'А/М',
        order: 'Заявка',
        comment: 'Комментарий',
        car_number: 'Номер а/м',
        prolong: 'Продлить',
        m_in: 'Завоз',
        m_out: 'Вывоз',
        storage: 'Хранение',
        photo: 'Фото',
        carrier: 'Перевозчик(если не известен водитель)',
        list_containers: 'Список контейнеров',
        add: 'Добавить',
        incorrect_number: "Не корректные номера контейнеров",
        list_containers_message: 'Введите список контейнеров. Номера контейнеров должны быть разделены пробелом, запятой или отдельной строкой',
        bills_title: 'Счета',
        no_bills: 'Нет счетов',
        bill_number: 'Номер счета',
        bill_amount: 'Сумма счета',
        bill_payment_status: "Оплата",
        bill_download: "Скачать",
        bill_registry: "Реестр",
        bill: "Счет",
        none: "Не оплачен",
        partial: "Оплачен частично",
        full: "Оплачен",
        bills: "Счета",
        download: "Скачать",
        empty: "Порожний",
        fullc: "Груженый",
        fullContainer: 'Груженый Контейнер',
        intact: "Исправный",
        damaged: "Требует ремонта",
        car_delivery: "Авто для вывоза",
        car_delivery2: "Авто для завоза",
        terminalpay:'Требуется оплата терминала',
        delivery_address: "Адрес",
        agreed_price_per_car: "Ставка за авто",
        per_car: " за один авто",
        close: "Завоз контейнеров осуществляется по предварительной записи",
        attention: "Внимание!",
        registration: "Нажмите на кнопку ниже.",
        enraice: "Вход в личный кабинет",
        containers_count: "Всего",
        repair_estimate:"Запросить стоимость ремонта контейнера ",
        repair_estimate2:"у терминала?",
        check_release:'Проверить релиз и забронировать место',
        check_release2:'Проверить',
        check_release3:'Наличие релиза или заявки по номеру контейнера',
        createdBy: "Создал",
        repairs: "Ремонты",
        repairs_title: "Заявки на ремонт",
        repairs_not: "Нет заявок на ремонт",
        price_repair: "Стоимость ремонта",
        crane_operations: "Крановые операции",
        estimate_date: "Дата расчета",
        full_container: ' - (груженый)',
        pc: "шт.",
        yes: "Да",
        no: "Нет",
        agree_estimate: "Принять предложенную стоимость ремонта контейнера",
        agree_estimate2: "?",
        accept: "Принять",
        refuse: "Отказаться",
        accept_cancel: "Принять/Отказаться",
        ready_repair: "Ремонт выполнен",
        waiting_import:"Ожидаем к завозу",
        waiting_export:"Ожидаем к вывозу",
        extend_application: "Продлить заявку",
        till: 'до',
        extend: "Продлить",
        _no: "№",
        refuse_to_refurbish: "Отказаться от ремонта",
        incorrect_password: "Не верный пароль",
        user_blocked: "Пользователь заблокирован",
        user_not_found: "Пользователь не найден",
        photo_containers: "Фото контейнеров",
        photo_containers_title: "Посмотреть фотографии контейнеров по номеру",
        reject_container_repair: "Отказаться от ремонта контейнера ",
        waiting: "На согласовании",
        generate_report: 'Сгенерировать отчет',
        range: 'Период',
        all: 'Все',
        show: 'Показать',
        application_insulation: "Заявка на утепление",
        insulation: "Утепление",
        one_layer: "1 слой (5 см)",
        two_layer: "2 слоя (10 см)",
        three_layer: "3 слоя (15 см)",
        roof: "Пол",
        plas: "Пленка",
        services: 'Услуги',
        services_title: "Заявки на услуги",
        services_not: "Нет заявок на услуги",
        number_out: "Номерная выдача",
        number_out_text: "Предполагается ли номерная выдача для контейнеров указанных в заявке?",
        photo_report: "Фотоотчет",
        photo_report_text: "Фотографирование с 4-ех сторон, стоимость согласно тарифа",
        list_container_type:"Тип контейнеров",
        list_containet_type_text:"Если в заявке указаны контейнеры одного типа, можете указать тип в этом поле",

    },
    [LOCALES.CHINESE]: {
        username: '用户名',
        password:
            '密码',
        terminal_operation:
            '用于终端运营与合作:',
        tel: '电话:',
        // меню
        login: '登录',
        working_mode: '工作模式:',
        working_mode_text: '每日全天候',
        container_terminal: 'Container Yard',
        esino: 'ESINO',
        exit: '登出',
        terminal_esino: 'Esino Container Yard',
        terminal_ccd: 'CCD East Container Yard',
        full_container: ' - (装载的集装箱)',
        containers: '集装箱',
        orders: '应用',
        movement: '移动',
        export_application: '申请出场',
        export_application2: 'Numbered issue',
        import_application: '申请进场',
        no_orders: '没有申请',
        date_from: '日期从',
        date_to:  "日期至",
        reload: 'Reload',
        reload_store: 'Reload and store',
        reload_application: 'Reload Application',
        container: "集装箱",
        type: "类型",
        total: "全部的",
        on_car: "在车上",
        ready: "准备好",
        release: "发布",
        hp_year1: "Dear terminal customers!",
        hp_year2: "We inform you that from 19:00 on 31-12-2024 to 10:00 on 01-01-2025, the ESINO terminal will not work.",
        hp_year3: "Please take this into account when planning your work.",
        applicant: "申请人",
        status: "地位",
        new: '新的',
        agreed: 'Согласовано',
        in_work: '工作中',
        closed: '关闭',
        denied:'拒绝',
        import: '送货',
        export: '出口',
        order_title_in: '根据以下详细信息申请将集装箱进口到码头',
        order_title_out: '使用以下详细信息申请从码头出口集装箱',
        order_title_reload: 'Заявка на перегруз контейнера по следующим реквизитам',
        order_title_reload_store: 'Заявка на перегруз и хранения контейнера на терминале по следующим реквизитам',
        issue_terminal:'来自终端的问题',
        accept_terminal: '在终端接受',
        additional_information:'附加信息',
        additional_email: '用于通知的附加电子邮件',
        containers_on_request:'应要求提供容器',
        size: '尺寸',
        tonnage: '吨位',
        quantity: '数量',
        number_container: '箱号',
        car_on_request:'应要求提供汽车',
        number: '数字',
        driver: '司机',
        add_container: '添加容器',
        add_auto: '添加自动',
        phone: '电话',
        create: '创建申请',
        save: '节省',
        cancel:'取消',
        cancel_order:'Order cancel',
        back: '后退',
        canceled: '取消',
        no_containers: '堆场无集装箱',
        state: '状态',
        cont: '内容',
        contf: 'Content(empty/full)',
        content: '内容',
        containers_title: '堆场的集装箱',
        days: '储存（天)',
        movings_title: '集装箱移动',
        no_movings: '没有移动',
        unblocked: '畅通',
        blocked: '封锁',
        overdue: '逾期',
        action: '行动',
        documents:'文件',
        date: '日期',
        stock: '库存',
        car: '车',
        order: '命令',
        comment: '评论',
        car_number: '车号',
        prolong: '延长',
        m_in: '送货',
        m_out: '出口',
        storage: '贮存',
        photo: '照片',
        carrier: '载体 (未知的司机)',
        list_containers: '添加容器列表',
        list_containers_message: '输入容器编号作为列表。 容器编号必须用空格或逗号分隔或另起一行。',
        add: '',
        incorrect_number: "不正确的容器编号",
        bills_title: '账单',
        no_bills: '没有账单',
        bill_number: '单号',
        bill_amount: '账单金额',
        bill_payment_status: "支付状态",
        bill_download: "下载",
        bill_registry: "注册表",
        bill: "账单",
        none: "未支付",
        partial: "部分支付",
        full: "有薪酬的",
        bills: "账单",
        download: "Download",
        empty: "Empty",
        fullc: "装载的集装箱",
        fullContainer: '装载的集装箱',
        intact: "Intact",
        damaged: "Damaged",
        car_delivery: "Car for delivery",
        car_delivery2: "Car for delivery",
        terminalpay:'Terminal payment required',
        delivery_address: "Address",
        agreed_price_per_car: "Agreed price per car",
        per_car: " per car",
        close: "Acceptance of containers requires prior appointment",
        attention: "Attention!",
        registration: "Click the button below.",
        enraice: "Login to account",
        containers_count: "Total",
        repair_estimate:"Request cost of repairing container",
        repair_estimate2:"from terminal?",
        photo_containers_title: "View photos of containers by number",
        send: 'Send',
        check_release:'Check release and book place',
        check_release2:'Check',
        check_release3:'Availability of a release or application by container number',
        created: "Created",
        createdBy: "Created by",
        repairs: "Repairs",
        repairs_title: "Repair requests",
        repairs_not: "No requests for repairs",
        price_repair: "Repair cost",
        crane_operations: "Crane operations",
        estimate_date: "Estimate date",
        pc: "PC",
        yes: "Yes",
        no: "No",
        agree_estimate: "Accept proposed container",
        agree_estimate2: "repair cost?",
        accept: "Accept",
        refuse: "Refuse",
        accept_cancel: "Accept/Cancel",
        ready_repair: "Repairs carried in",
        waiting_import:"Waiting to import",
        waiting_export:"Waiting to export",
        extend_application: "Extend application",
        till: "till",
        extend: "Extend",
        _no: "#",
        refurbished: "Refurbished",
        refused_to_refurbish: "Refused to refurbish",
        refuse_to_refurbish: "Refuse to refurbish",
        incorrect_password: "Incorrect password",
        user_blocked: "This user blocked",
        user_not_found: "User not found",
        photo_containers: "Photo containers",
        reject_container_repair: "Reject container repair ",
        waiting: "Waiting",
        generate_report: 'Generate report',
        range: 'Date period',
        all: 'All',
        show: 'Show',
        application_insulation: "Application for insulation",
        insulation: "Insulation",
        one_layer: "1 layer (5 cm)",
        two_layer: "2 layers (10 cm)",
        three_layer: "3 layers (15 cm)",
        roof: "Roof",
        plas: "Polyethylene",
        services: 'Services',
        services_title: "Services requests",
        services_not: "No requests for services",
        number_out: "Numbered issue",
        number_out_text: "Is numbered issuance expected for the containers listed below?",
        photo_report: "Photo report",
        photo_report_text: "Photography from 4 sides, cost according to the tariff",
        list_container_type:"Container type",
        list_containet_type_text:"If the request contains a container of the same type, you can indicate in this field",
    }
}