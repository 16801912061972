import React, {useContext, useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Context} from "../index";
import '../css/login.css';
import Icon from "@reacticons/bootstrap-icons";
import {YMInitializer} from 'react-yandex-metrika';
import {FormattedMessage, useIntl} from 'react-intl'
import axios from "axios";

const LoginForm = () => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const {store} = useContext(Context);
    const [terset, setTerset] = useState(0);
    const [error, setError] = useState('');
    const intl = useIntl();


    const _tusername = `${intl.messages.username}`;
    const _tpassword = `${intl.messages.password}`;
    const languageRu = () => {
        store.setLocale('ru-RU')
        localStorage.setItem('locale','ru-RU')
    }
    const _setting = async () => {
        let api = axios.create({
            withCredentials: false,
            baseURL: '/set.txt',

        })
        let response  = await api.get();
        if(response.status == 200) {
            //console.log(response.data);
            setTerset(response.data);
        }
    }
    const login = async (username, password) => {
        let response = await store.login(username, password)
        if(response.data.success === false){
            setError(response.data.error)
        }
    }
    useEffect(() => {
        _setting();
        setError(store.error)
    }, [store])

    const languageEn = () => {
        store.setLocale('en-US')
        localStorage.setItem('locale','en-US')
    }
    const languageZh = () => {
        store.setLocale('zh-CN')
        localStorage.setItem('locale','zh-CN')
    }

    return (
        <>
            <div className="loginForm">
                <div className="overlord">
                    <div className="fixed-top text-secondary">
                        <Container className="mt-2">
                            <span onClick={languageRu} className={(store.locale === 'ru-RU')?'lang active': 'lang'}>Рус</span><span> / </span>
                             <span onClick={languageEn} className={(store.locale === 'en-US')?'lang active': 'lang'}>Eng</span><span> / </span>
                             <span onClick={languageZh} className={(store.locale === 'zh-CN')?'lang active': 'lang'}>中文</span>

                        </Container>
                    </div>
                    <Container className="pb-5">

                        <Row className="justify-content-md-center mt-5 py-5">

                            <Col xs lg="4">
                                <img className="img-logo" src="/logo.svg"
                                     alt={intl.messages.container_terminal + " " + intl.messages.esino}/>
                                <span className={(store.locale === 'ru-RU')?'logo': 'logo-en'}><FormattedMessage
                                    id='container_terminal'/><br/><span><FormattedMessage id='esino'/></span></span>
                            </Col>
                        </Row>

                        <Row className="justify-content-md-center mt-2">
                            {terset ?
                            <Col xs lg="6" className="text-center alert alert-danger">
                                <h3><FormattedMessage id='attention'/></h3>
                                <h6><FormattedMessage id='close'/>. <FormattedMessage id='registration' /></h6>
                            </Col>  : null }

                        </Row>
                        <Row className="justify-content-md-center mt-1 mb-5">
                            <Col xs lg="5" className="text-center ">
                                { terset ? <a className="btn btn-danger btn-block_ btn-lg py-3" href="/check/" ><FormattedMessage id='check_release' /></a>

                                     :
                                    <div className="alert alert-info"> <h3 className="text-primary mb-3">
                                        <FormattedMessage id='check_release3' />
                                    </h3>
                                        <a className="btn btn-danger btn-block_ btn-lg py-3" href="/check/" ><h2><FormattedMessage id='check_release2' /></h2></a>
                                    </div>

                                }
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mb-5">
                            <Col xs lg="5" className="text-center ">
                                <div className="alert alert-info"><h3 className="text-primary mb-3">
                                    <FormattedMessage id='hp_year1'/>
                                </h3>
                                    <h5 className="text-dark mb-3">
                                        <FormattedMessage id='hp_year2'/>
                                    </h5>
                                    <h4 className="text-danger mb-3">
                                        <FormattedMessage id='hp_year3'/>
                                    </h4>

                                </div>

                            </Col>
                        </Row>
                        <div className="alert alert-info">
                        <Row className="justify-content-md-center mt-3">
                            <Col xs lg="4" className="text-center">

                                    <h4> <FormattedMessage id='enraice'/></h4>

                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-1">
                            <Col xs lg="4">
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="text" placeholder={_tusername}
                                                      onChange={e => setUsername(e.target.value)}></Form.Control>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Control type="password" placeholder={_tpassword}
                                                      onChange={e => setPassword(e.target.value)}/>
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <Button className="btn-block_" variant="primary" type="button"
                                                onClick={() => login(username, password)}>
                                            <FormattedMessage id='login'/>
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                        </div>
                        <Row className="justify-content-md-center mt-1">
                            {error ?
                                <Col xs lg="6" className="text-center alert alert-danger">
                                    <h6><FormattedMessage id={error} /></h6>
                                </Col>  : null }
                        </Row>


                    </Container>
                    <div className="bg-dark text-light mt-4">
                        <iframe title='yandexmap'
                            src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad11efe8c1910cbad6fb58a58f03d80b0b59ffa651b8cbc2886fd1daaae0f7909&amp;source=constructor"
                            width="100%" height="180" frameBorder="0"></iframe>

                        <Container>
                            <Row className="mt-3 mb-1">
                                <Col xs lg="6" className="">
                                    <h6><FormattedMessage id='terminal_operation'/></h6>
                                    <div><FormattedMessage id='tel'/> <a href="tel:+79916268610">+7 (991) 626-86-10</a>
                                        <a
                                            href="https://t.me/+79916268610"><Icon name="telegram"/></a> <a
                                            href="https://api.whatsapp.com/send?phone=79916268610"><Icon
                                            name="whatsapp"/></a>
                                    </div>
                                    <div><FormattedMessage id='tel'/> <a href="tel:+74951085031">+7 (495) 108-50-31</a>
                                    </div>
                                    <div>WeChat ID: VladAndr</div>

                                </Col>
                                <Col xs lg="6" className="">
                                    <h6><FormattedMessage id='working_mode'/></h6>
                                    <div><FormattedMessage id='working_mode_text'/></div>
                                    <div>E-mail: <a href="mailto:esino@rzdu.ru">esino@rzdu.ru</a></div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <YMInitializer accounts={[93302674]} options={{
                clickmap: false,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: false
            }}/>
        </>
    );

}

export default LoginForm;